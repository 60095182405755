import { type IdxMessage, type Input } from '@okta/okta-auth-js'

import type { SignInMethodType, IdxFeatureCustomStepName } from './types'

const labelMap: {
  [key: string]: { labelText: string; labelCaption?: string }
} = {
  rememberMe: {
    labelText: 'Keep me signed In',
    labelCaption: `Check this box only if you're on a personal, non-shared device.`
  },
  username: { labelText: 'Email address' },
  Email: { labelText: 'Email me a link' },
  Password: { labelText: 'Sign in with password' }
} as const

export function convertErrorMessage(message: IdxMessage): string {
  switch (message.i18n.key) {
    case 'registration.error.notUniqueWithinOrg':
      return 'A user with this Email already exists'
    case 'errors.E0000004':
      return 'Email and password do not match. Please try again or reset your password.'
    case 'api.authn.error.PASSCODE_INVALID':
      return 'The code you entered is incorrect. Please check your text message or email for the correct code and try again.'
    case 'oie.selfservice.reset.password.not.allowed':
      return 'Try again using the social sign-in provider you used to create your account.'
    case 'invalid_token':
      return 'Link expired'
    default:
      return message.message
  }
}

export function getLabel(
  name?: string,
  defaultLabel?: string
): { labelText: string; labelCaption?: string } {
  return (
    (name && labelMap[name]) ||
    (defaultLabel && { labelText: defaultLabel }) || { labelText: '' }
  )
}

export function getStepName(
  nextStep: { name: string; type?: string },
  isVerifywithSomethingElse: boolean
): IdxFeatureCustomStepName {
  if (isVerifywithSomethingElse) {
    return 'select-authenticator-authenticate'
  }
  // TODO add type guard
  let nextStepName = nextStep.name as IdxFeatureCustomStepName
  if (
    nextStep.name === 'challenge-authenticator' &&
    nextStep.type === 'password'
  ) {
    nextStepName = 'challenge-authenticator-password'
  } else if (
    nextStep.name === 'challenge-authenticator' &&
    nextStep.type === 'email'
  ) {
    nextStepName = 'challenge-authenticator-email'
  } else if (
    nextStep.name === 'challenge-authenticator' &&
    nextStep.type === 'phone'
  ) {
    nextStepName = 'challenge-authenticator-phone'
  } else if (
    nextStep.name === 'select-authenticator-enroll' &&
    nextStep.type === 'password'
  ) {
    nextStepName = 'select-authenticator-enroll-password'
  } else if (
    nextStep.name === 'select-authenticator-enroll' &&
    nextStep.type === 'phone'
  ) {
    nextStepName = 'select-authenticator-enroll-phone'
  } else if (
    nextStep.name === 'enroll-authenticator' &&
    nextStep.type === 'phone'
  ) {
    nextStepName = 'enroll-authenticator-phone'
  }
  return nextStepName
}

export function preSubmit({
  name,
  signInMethod
}: {
  name: string
  signInMethod?: SignInMethodType
}) {
  if (signInMethod === 'email') {
    return { authenticator: 'okta_email' }
  }
  if (signInMethod === 'password') {
    return { authenticator: 'okta_password' }
  }
  if (signInMethod === 'sms') {
    return { authenticator: 'phone_number' }
  }
  if (name === 'select-authenticator-unlock-account') {
    return { authenticator: 'okta_email' }
  }
  if (name === 'authenticator-verification-data') {
    return { methodType: 'email' }
  }
  if (name === 'authenticator-enrollment-data') {
    return { methodType: 'sms' }
  }
}

// read though what factors are avaiable
export function factorsAvailable(inputs: Input[]) {
  // this is okta code to filter out form elements
  const filteredInputs = inputs?.filter(
    input => !!input.label || !!input.options
  )
  const factors: string[] = []
  if (!filteredInputs?.length) {
    return []
  }

  filteredInputs.forEach(({ options }) => {
    options?.forEach(({ value }) => {
      if (typeof value === 'string') {
        factors.push(value)
      }
    })
  })
  return factors
}
