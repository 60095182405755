import React, { type Dispatch, useState } from 'react'
import {
  Text,
  Box,
  Button,
  Input,
  getPaletteColor,
  FormField,
  Label,
  Flex
} from 'pcln-design-system'
import styled, { keyframes } from 'styled-components'
import OktaAuth from '@okta/okta-auth-js'
import { Warning } from 'pcln-icons'
import { Loader } from '@pcln/loader'
import { challengeEmail, refreshToken, verifyEmail } from './OktaService'

const Seperator = styled(Flex)`
  align-items: center;
  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: ${getPaletteColor('border.base')};
    flex-grow: 1;
    margin-left: 8px;
    margin-right: 8px;
  }
`

const right = keyframes`
from {
  margin-left: 5%;
}
to {
  margin-left: 0%;
}
`
const CustomFlex = styled(Flex)`
  animation-name: ${right};
`

export default function VerifyEmail({
  accessToken,
  emailID,
  oktaClient,
  buttonsDisabled,
  setButtonsDisabled
}: {
  accessToken: string
  emailID: string
  oktaClient: OktaAuth
  buttonsDisabled: boolean
  setButtonsDisabled: Dispatch<boolean>
}) {
  const [challengeID, setChallengeID] = useState('')
  const [inputMessage, setInputMessage] = useState('')
  const [makeOTPCall, setMakeOTPCall] = useState(false)
  const [inputOTP, setInputOTP] = useState('')

  async function SkipVerification() {
    sessionStorage.setItem('shouldSkipEmailVerifaction', 'true')
    await refreshToken(oktaClient)
    window.location.reload()
  }

  const handleOTPChange = ({
    target: { value }
  }: {
    target: { value: string }
  }) => {
    return setInputOTP(value)
  }

  async function sendVerifyEmail() {
    setMakeOTPCall(true)
    setButtonsDisabled(true)
    const errHandler = async () => {
      await SkipVerification()
      setInputMessage('Something went wrong. Please try later. Siging in.')
    }
    const response = await challengeEmail(emailID, accessToken, errHandler)

    const { ok, body } = { ...response }
    if (ok && body) {
      if (body.status === 'UNVERIFIED') {
        setChallengeID(body.id)
      }
    } else {
      await SkipVerification()
    }
    setButtonsDisabled(false)
  }

  const VerifyOTP = async () => {
    setButtonsDisabled(true)

    const response = await verifyEmail(
      inputOTP,
      challengeID,
      emailID,
      accessToken
    )

    if (response.ok) {
      await refreshToken(oktaClient)
      window.location.reload()
    } else if (response.ok === false && response?.errorReason) {
      setButtonsDisabled(false)
      setInputMessage(response?.errorReason)
    } else {
      setButtonsDisabled(false)
      setInputMessage('Something went wrong. Please try later. Siging in.')
      await SkipVerification()
    }
  }

  return (
    <>
      <Text textStyle={['heading4', null, null, 'heading2']} mb={2}>
        Verify your email
      </Text>
      <Text textStyle="paragraph" mb={24}>
        Verify your email to complete registration and ensure the security of
        your account.
      </Text>
      {!challengeID ? (
        <Box>
          <Button
            size="large"
            variation="fill"
            type="submit"
            my={3}
            data-testid="verifyEmail"
            width="100%"
            disabled={buttonsDisabled}
            onClick={() => {
              void sendVerifyEmail()
            }}
          >
            {makeOTPCall ? (
              <CustomFlex
                justifyContent="space-around"
                data-testid="loader-dots"
              >
                <Box width="90px">
                  <Loader />
                </Box>
              </CustomFlex>
            ) : (
              <Text fontWeight="bold">Verify Email</Text>
            )}
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <Text textStyle="paragraph" mb={24}>
              Check the email we just sent you and enter the code below.
            </Text>
          </Box>
          <FormField>
            <Label pb={0} autoHide={!inputOTP} width="auto">
              Enter Code
            </Label>
            <Input
              name="inputOTP"
              id="inputOTP"
              aria-invalid={!!inputMessage}
              placeholder="Enter Code"
              color={inputMessage ? 'error' : undefined}
              type="input"
              aria-describedby="input-otp"
              onChange={handleOTPChange}
            />
          </FormField>
          {inputMessage && (
            <Input.HelperText color="error" aria-live="assertive">
              <span id="input-otp">
                <Flex pb={1}>
                  <Warning size={16} />
                  <Text color="error" textStyle="caption" pl={1}>
                    {inputMessage}
                  </Text>
                </Flex>
              </span>
            </Input.HelperText>
          )}
          <Button
            size="large"
            type="submit"
            variation="fill"
            my={3}
            width="100%"
            disabled={buttonsDisabled}
            onClick={() => {
              void VerifyOTP()
            }}
          >
            <Text fontWeight="bold">Submit</Text>
          </Button>
          <Box mb={3} />
        </>
      )}
      <Seperator>
        <Text textStyle="paragraph" color="text.light">
          or
        </Text>
      </Seperator>
      <Box mb={3} />

      <Button
        variation="subtle"
        size="large"
        onClick={() => {
          void SkipVerification()
        }}
        width="100%"
        mb="12px"
      >
        Remind me later
      </Button>
    </>
  )
}
