import type { GA4PageNameType } from '@/ga4types'
import analytics from '@/shared-utils/analytics'
import { type FlowIdentifier } from '@okta/okta-auth-js'

function flowToPageName(flow: FlowIdentifier) {
  const flowToPageMapping: { [key: string]: GA4PageNameType } = {
    authenticate: 'sign_in',
    register: 'account_creation'
  }
  return flowToPageMapping[flow]
}

export function firePageViewEvent(activeFlow: FlowIdentifier) {
  const pageName = flowToPageName(activeFlow)

  /** Don't fire when no such mapping exists */
  if (pageName) {
    analytics.fireGA4Event({
      event: 'page_view',
      attributes: {
        page_name: pageName
      }
    })
  }
}

export function fireLoginEvent(signInMethod: string) {
  analytics.fireGA4Event({
    event: 'login',
    attributes: {
      method: signInMethod
    }
  })
}
