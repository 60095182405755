import React, { useEffect, useState, type SyntheticEvent } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import styled, { keyframes } from 'styled-components'
import {
  Text,
  Box,
  Input,
  Button,
  Flex,
  getPaletteColor,
  Layout,
  Spinner,
  Image,
  FormField,
  Label,
  Toggle,
  GenericBanner,
  IconButton,
  Link,
  type BoxProps
} from 'pcln-design-system'
import { Information, Warning, Visibility, VisibilityOff } from 'pcln-icons'
import PhoneInput from '@pcln/phone-input'
import analytics from '@/shared-utils/analytics'
import {
  type IdxMessages,
  IdxStatus,
  type IdxRemediation
} from '@okta/okta-auth-js'
import { Loader } from '@pcln/loader'
import useBootstrapData from '@/hooks/useBootstrapData'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import type {
  RootTypes,
  InputType,
  SignInMethodType,
  IdxFeatureCustomStepName
} from './utils/types'
import formTransformer from './utils/FormTransformer'
import capitalizeLetter from '../../utils/capitalize-letter'
import {
  convertErrorMessage,
  factorsAvailable,
  getLabel,
  getStepName,
  preSubmit
} from './utils/util'
import Content from './content'
import validationSchema from './utils/validation'

type IdxOption = NonNullable<
  NonNullable<NonNullable<IdxRemediation['value']>[number]['options']>[number]
>

const authName = {
  okta_email: {
    authenticator: 'okta_email',
    methodType: 'email'
  },
  phone_number: {
    authenticator: 'phone_number',
    methodType: 'sms'
  }
} as const
const Seperator = styled(Flex)`
  align-items: center;
  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: ${getPaletteColor('border.base')};
    flex-grow: 1;
    margin-left: 8px;
    margin-right: 8px;
  }
`
const InputField = styled(Input)`
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
`

const IconButtonStyled = styled(IconButton)`
  z-index: 1;
`
const right = keyframes`
from {
  margin-left: 5%;
}

to {
  margin-left: 0%;
}
`
const left = keyframes`
from {
  margin-left: -5%;
}

to {
  margin-left: 0%;
}
`
const BoxCustom = styled(Box)<BoxProps & { slideInRight?: boolean }>`
  position: relative;
  width: 100%;
  animation-duration: 0.3s;
  animation-name: ${props => (props.slideInRight ? right : left)};
`
function GetMethodType(inputType: string) {
  let methodType
  if (inputType === 'Phone') {
    methodType = 'sms'
  } else if (inputType === 'Email') {
    methodType = 'email'
  }
  return methodType
}
function Headline({ headline }: { headline: string }) {
  return (
    <Text textStyle={['heading4', null, null, 'heading2']} mb={2}>
      {headline}
    </Text>
  )
}

function SubHeading({ subHeading }: { subHeading: string }) {
  return (
    <Text textStyle="paragraph" mb={24}>
      {subHeading}
    </Text>
  )
}
function SeperatorLine({ text }: { text?: string }) {
  return (
    <Seperator>
      <Text textStyle="paragraph" color="text.light">
        {text}
      </Text>
    </Seperator>
  )
}

function ResetPassword({
  onClick
}: {
  onClick: () => void
}): React.JSX.Element {
  return (
    <Flex justifyContent="center" my={3}>
      <Button variation="link" onClick={() => onClick()}>
        <Text fontWeight="bold">Reset Password</Text>
      </Button>
    </Flex>
  )
}

function SwitchAuthMethod({
  buttonProcessing,
  onClick,
  text
}: {
  buttonProcessing: boolean
  onClick: () => void
  text: string
}) {
  return (
    <Button
      size="large"
      type="submit"
      my={3}
      width="100%"
      variation="subtle"
      disabled={buttonProcessing}
      onClick={() => onClick()}
    >
      <Text fontWeight="bold">{text}</Text>
    </Button>
  )
}
function VerifyWithSomethingElse({
  options,
  stepName,
  buttonProcessing,
  chooseAuthincatorType
}: {
  options: IdxOption[]
  stepName: IdxFeatureCustomStepName
  buttonProcessing: boolean
  chooseAuthincatorType: (value: string) => void
}): React.JSX.Element {
  return (
    <Box
      mb={12}
      display={
        options.length <= 1 && stepName !== 'select-authenticator-enroll'
          ? 'none'
          : 'block'
      }
    >
      <Text textStyle="paragraph" mb={24}>
        How would you like to verify?
      </Text>
      <Flex flexDirection="column" style={{ gap: 16 }}>
        {options.map(({ value }) => {
          if (typeof value === 'string') {
            const customLabel =
              value === 'okta_email' ? 'Email Me a Link' : 'Text Me a Code'
            return (
              <Button
                size="large"
                type="submit"
                variation="subtle"
                disabled={buttonProcessing}
                width="100%"
                onClick={() => chooseAuthincatorType(value)}
              >
                {customLabel}
              </Button>
            )
          }
          return null
        })}
      </Flex>
    </Box>
  )
}

function ErrorMessaage({ message }: { message: string }) {
  return (
    <GenericBanner
      color="caution.light"
      borderRadius="lg"
      iconLeft={<Information />}
      py={2}
      px={3}
      mb={12}
      text={<Text>{message}</Text>}
    />
  )
}
function InputErrorMessage({
  messages,
  isOnResetPassoword
}: {
  messages: IdxMessages
  isOnResetPassoword: boolean
}) {
  return (
    <>
      {messages.value.map(message => {
        const showCustomPasswordRequirments =
          isOnResetPassoword &&
          message.i18n.key === 'password.passwordRequirementsNotMet'
        const inputErrorMessage = showCustomPasswordRequirments
          ? convertErrorMessage({
              class: 'ERROR',
              message:
                'Password requirements were not met. Password requirements: at least 8 characters, a lowercase letter. Your password cannot be any of your last 5 passwords.',
              i18n: {
                key: 'password.passwordRequirementsNotMetCustom'
              }
            })
          : convertErrorMessage(message)

        return (
          <Flex pb={1}>
            <Warning size={16} />
            <Text color="error" textStyle="caption" pl={1}>
              {inputErrorMessage}
            </Text>
          </Flex>
        )
      })}
    </>
  )
}

type GeneralFormTypes = Omit<RootTypes, 'setShowSpinner'>

function GeneralForm({
  redirectUrl,
  transaction,
  setTransaction,
  errorMessage = '',
  setErrorMessage,
  showSpinner = false,
  buttonsDisabled,
  slideInRight,
  setSlideInRight,
  setButtonsDisabled
}: GeneralFormTypes) {
  const { oktaAuth } = useOktaAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [isVerifywithSomethingElse, setIsVerifywithSomethingElse] =
    useState(false)
  const [buttonProcessing, setButtonProcessing] = useState(false)
  const [isOn, setIsOn] = useState(false)
  const [shouldSkipEnrollStep, setShouldSkipEnrollStep] = useState(false)
  const { isMobile } = useBootstrapData()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    const dontReset = ['enroll-profile', 'identify']
    if (
      (transaction?.nextStep?.name &&
        !dontReset.includes(transaction?.nextStep?.name)) ||
      showSpinner
    ) {
      reset()
    }
  }, [reset, transaction, showSpinner])

  const dealsCheckbox = isMobile
    ? 'mwebPromoFlagOnRegister'
    : 'desktopPromoFlagOnRegister'
  const checkboxNames = [dealsCheckbox, 'rememberMe']

  const onToggle = (name: string) => {
    setIsOn(!isOn)
    setValue(name, !isOn, { shouldDirty: true })
  }

  function phoneNumberOnChange({
    name,
    value
  }: {
    name: string
    isValid: boolean
    value: string
  }) {
    setValue(name, value, { shouldValidate: true })
  }
  const handleChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget
    setValue(name, value, { shouldValidate: true })
  }
  const handleSkip = async () => {
    const newTransaction = await oktaAuth.idx.proceed({ skip: true })
    setTransaction(newTransaction)
  }
  async function chooseAuthincatorType(value: string) {
    setButtonProcessing(true)
    const newTransaction = await oktaAuth.idx.proceed({
      authenticator: value
    })
    setTransaction(newTransaction)
    setSlideInRight(true)
    setButtonProcessing(false)
  }
  async function goToFlow(currentAuthenticator: 'okta_email' | 'phone_number') {
    const switchTransaction = await oktaAuth.idx.proceed({
      authenticator: authName[currentAuthenticator].authenticator
    })
    if (
      switchTransaction?.nextStep?.name === 'authenticator-verification-data'
    ) {
      const skipStepTransaction = await oktaAuth.idx.proceed({
        methodType: authName[currentAuthenticator].methodType
      })
      return skipStepTransaction
    }
    return switchTransaction
  }
  async function handleOktaSubmit({
    forgetPasswordEmail,
    signInMethod
  }: {
    forgetPasswordEmail?: string
    signInMethod?: SignInMethodType
  }) {
    analytics.fireEvent({
      category: 'Global Register Modal',
      action: 'Submit Button',
      label: 'Standalone Page'
    })
    const preSubmitValues =
      transaction?.nextStep &&
      preSubmit({
        name: transaction?.nextStep.name,
        signInMethod
      })
    const submitInputValues = signInMethod
      ? { ...preSubmitValues }
      : { ...preSubmitValues, ...getValues() }
    setErrorMessage('')
    setButtonProcessing(true)
    setButtonsDisabled(true)
    try {
      const recoverPassword = forgetPasswordEmail && {
        username: forgetPasswordEmail
      }
      const newTransaction = recoverPassword
        ? await oktaAuth.idx.recoverPassword(recoverPassword)
        : await oktaAuth.idx.proceed(submitInputValues)

      if (newTransaction.status === IdxStatus.PENDING) {
        const { inputs } = newTransaction.nextStep || {}

        if (inputs) {
          const shouldShowInputError = inputs.some(
            (input: InputType) =>
              input.messages !== undefined && input.type !== 'boolean'
          )
          if (!shouldShowInputError) {
            const error = newTransaction?.messages
              ? convertErrorMessage(newTransaction?.messages[0])
              : ''
            setErrorMessage(error)
          }
        }
      }
      sessionStorage.setItem('standalone_signin_with', 'pcln')
      setIsVerifywithSomethingElse(false)
      localStorage.setItem('standalone_redirect', redirectUrl)

      // sign in - if email is verified then switch to it
      // else if sms is verified then switch to that
      // else will not change transaction and go to default
      // if we add new type of verifier then this will need to be updated
      if (
        newTransaction?.nextStep?.name === 'select-authenticator-authenticate'
      ) {
        const factorsAvailableArr =
          newTransaction.nextStep.inputs &&
          factorsAvailable(newTransaction.nextStep.inputs)
        if (factorsAvailableArr?.includes('okta_email')) {
          const switchedTransaction = await goToFlow('okta_email')
          setTransaction(switchedTransaction)
          setSlideInRight(true)
          return
        }
        if (factorsAvailableArr?.includes('phone_number')) {
          const switchedTransaction = await goToFlow('phone_number')
          setTransaction(switchedTransaction)
          setSlideInRight(true)
          return
        }
      }
      // only show select-authenticator-enroll step once.
      // skip if user has already validated one additional auth method
      if (newTransaction?.nextStep?.name === 'select-authenticator-enroll') {
        setShouldSkipEnrollStep(true)
      }
      if (
        newTransaction?.nextStep?.name === 'select-authenticator-enroll' &&
        shouldSkipEnrollStep
      ) {
        await handleSkip()
        return
      }
      // if its only one type auth method then preselect it
      // TODO: not needed once we decide to keep sms authenticator
      if (
        newTransaction?.nextStep?.name === 'select-authenticator-enroll' &&
        newTransaction.nextStep?.inputs &&
        newTransaction.nextStep?.inputs[0].options?.length === 1
      ) {
        const skipStepTransaction = await oktaAuth.idx.proceed({
          authenticator: 'okta_email'
        })
        setTransaction(skipStepTransaction)
        return
      }
      // skip click here to send email screen
      if (
        newTransaction?.nextStep?.name === 'authenticator-verification-data' &&
        newTransaction.nextStep.authenticator?.displayName
      ) {
        const skipStepTransaction = await oktaAuth.idx.proceed({
          methodType: GetMethodType(
            newTransaction.nextStep.authenticator?.displayName
          )
        })
        setTransaction(skipStepTransaction)
        setSlideInRight(true)
        return
      }

      setTransaction(newTransaction)
      setSlideInRight(true)
    } catch (error) {
      const catchErrorMessage =
        error instanceof Error
          ? error.message
          : error?.toString() ?? 'Unknown error'
      analytics.logError({
        message: `Standalone - Okta transaction failed with error: ${catchErrorMessage}`
      })
      if (catchErrorMessage.includes('select-authenticator-unlock-account')) {
        try {
          const newTransaction = await oktaAuth.idx.startTransaction({
            flow: 'unlockAccount'
          })
          setTransaction(newTransaction)
          setErrorMessage(
            'Your account is locked. Please enter your email to unlock it.'
          )
        } catch (err) {
          const errorMessageForUnlockAccount =
            err instanceof Error
              ? err.message
              : err?.toString() ?? 'Unknown error'
          analytics.logError({
            message: `Standalone - Okta transaction failed with error: ${errorMessageForUnlockAccount}`
          })
        }
      } else {
        setErrorMessage('Something went wrong. Please refresh and try again.')
      }
    } finally {
      setButtonProcessing(false)
      setButtonsDisabled(false)
    }
  }

  if (!transaction || !transaction.nextStep || showSpinner) {
    return (
      <Flex justifyContent="space-around">
        <Spinner data-testid="spinner" />
      </Flex>
    )
  }

  const arr = ['methodType', 'authenticator']
  const { nextStep, availableSteps, context } = transaction
  const phoneNumber = nextStep.authenticator?.profile?.phoneNumber
  const userEmail = context.user?.value.identifier
  const userIdentifier = phoneNumber || userEmail

  const { canSkip } = nextStep
  const stepName = getStepName(nextStep, isVerifywithSomethingElse)
  const idps = availableSteps?.filter(
    (step: { name: string }) => step.name === 'redirect-idp'
  )
  const form = formTransformer(nextStep)({} /* initial form value */)

  const isOnPasswordVerifyPage = stepName === 'challenge-authenticator-password'
  const isOnEmailVerifyCodePage = stepName === 'challenge-authenticator-email'
  const isOnPhoneVerifyCodePage = stepName === 'challenge-authenticator-phone'
  const authMethodsAvaiable = availableSteps?.find(
    e => e.name === 'select-authenticator-authenticate'
  )
  const authMethodsAvaiableNames =
    authMethodsAvaiable &&
    authMethodsAvaiable.inputs &&
    authMethodsAvaiable.inputs[0] &&
    authMethodsAvaiable.inputs[0].options &&
    authMethodsAvaiable.inputs[0].options.map(e => {
      return e.label
    })

  const isPhoneVerified = authMethodsAvaiableNames?.includes('Phone')
  const isEmailVerified = authMethodsAvaiableNames?.includes('Email')
  const isPasswordVerified = authMethodsAvaiableNames?.includes('Password')

  const { inputs }: { inputs: InputType[] } = form
  const headline =
    Content[stepName]?.heading ||
    stepName
      .split('-')
      .map((str: string) => capitalizeLetter(str))
      .join(' ')
  const subHeading = Content[stepName]?.subHeading || ''

  const idpClick = async (idpName: string) => {
    localStorage.setItem('standalone_redirect', redirectUrl)
    setButtonsDisabled(true)
    try {
      await oktaAuth.idx.cancel()
      const result = await oktaAuth.idx.authenticate()
      result.availableSteps?.forEach(e => {
        if (e.name === 'redirect-idp') {
          if (e?.idp?.name === idpName) {
            sessionStorage.setItem('standalone_signin_with', idpName)
            window.location.href = e.href as string
          }
        }
      })
    } catch (error) {
      const catchErrorMessage =
        error instanceof Error
          ? error.message
          : error?.toString() ?? 'Unknown error'
      analytics.logError({
        message: `Standalone - failed to get new links for social sign in ${catchErrorMessage}`
      })
      setErrorMessage(
        `There was a problem signing you in with your social account. Please refresh the page to try again.`
      )
    }
  }

  inputs.push(
    inputs.splice(
      inputs.findIndex(item => item.name === dealsCheckbox),
      1
    )[0]
  )
  function switchAuthMethod(methodType: SignInMethodType) {
    setErrorMessage('')
    void handleOktaSubmit({ signInMethod: methodType })
  }

  const canResetPassword = transaction.availableSteps?.find(
    step => step.name === 'currentAuthenticator-recover'
  )
  return (
    <BoxCustom mb={3} key={stepName} slideInRight={slideInRight}>
      <form
        onSubmit={e => e.preventDefault()}
        id="okta-nl-form"
        name="auth-form"
      >
        <Box mb={3}>
          <Flex justifyContent="space-between">
            <Box width={7 / 8}>
              <Headline headline={headline} />
              <SubHeading subHeading={subHeading} />
              <Text textStyle="paragraph" mb={24}>
                {userIdentifier}
              </Text>
            </Box>
            <Box mb={3} ml={3} width={1 / 8}>
              <Image
                src="https://s1.pclncdn.com/design-assets/home/okta/vip.svg"
                width="58"
                height="58"
                alt="VIP logo"
                loading="lazy"
              />
            </Box>
          </Flex>
          <Box id="form-messages">
            {errorMessage && <ErrorMessaage message={errorMessage} />}
          </Box>
          {inputs &&
            inputs.map((input: InputType) => {
              const {
                label: inputLabel,
                name,
                type,
                required,
                options,
                messages: inputMessages
              } = input

              const { labelText, labelCaption } = getLabel(name, inputLabel)

              if (options && arr.includes(name)) {
                return (
                  <VerifyWithSomethingElse
                    options={options}
                    stepName={stepName}
                    buttonProcessing={buttonProcessing}
                    chooseAuthincatorType={value =>
                      chooseAuthincatorType(value)
                    }
                  />
                )
              }

              if (type === 'checkbox') {
                return (
                  checkboxNames.includes(name) && (
                    <Flex
                      bg="background"
                      borderRadius="lg"
                      py={2}
                      key={labelText}
                      px={12}
                      mb={12}
                      justifyContent="space-between"
                    >
                      <label htmlFor={name}>
                        <Text
                          textStyle={['paragraph', null, null, 'paragraph']}
                        >
                          {labelText}
                        </Text>
                        {labelCaption && (
                          <Text textStyle={['caption', null, null, 'caption']}>
                            {labelCaption}
                          </Text>
                        )}
                      </label>
                      <Flex alignItems="center">
                        <Toggle
                          {...register(name, { required })}
                          name={name}
                          label={inputLabel}
                          onToggle={() => onToggle(name)}
                          isOn={isOn}
                        />
                      </Flex>
                    </Flex>
                  )
                )
              }
              if (name === 'phoneNumber') {
                return (
                  <Box mb={12}>
                    <PhoneInput
                      onChange={(isValid, value) =>
                        phoneNumberOnChange({ name, isValid, value })
                      }
                    />
                  </Box>
                )
              }
              const value = getValues(name)

              const inputType =
                type === 'password' && !showPassword ? 'password' : 'text'
              const VisibilityIcon = showPassword ? VisibilityOff : Visibility

              const hasClientSideErrors = !!errors[name]
              const hasInputMessages = !!inputMessages
              const hasErrors = hasClientSideErrors || hasInputMessages

              return (
                <Box mb={12} key={labelText}>
                  <FormField>
                    <Label pb={0} autoHide={!value} width="auto">
                      {labelText}
                    </Label>
                    <InputField
                      {...register(name, { required: true })}
                      name={name}
                      id={name}
                      data-testid={name}
                      aria-invalid={hasErrors ? 'true' : 'false'}
                      placeholder={labelText}
                      color={inputMessages ? 'error' : undefined}
                      type={inputType}
                      aria-describedby={`helper-text-${name}`}
                      onChange={handleChange}
                    />
                    {type === 'password' && (
                      <IconButtonStyled
                        type="button"
                        aria-label="Show/hide password"
                        aria-pressed={showPassword}
                        icon={<VisibilityIcon color="text.light" />}
                        onClick={(e: SyntheticEvent) => {
                          e.preventDefault()
                          setShowPassword(!showPassword)
                        }}
                      />
                    )}
                  </FormField>
                  {hasErrors ? (
                    <Input.HelperText color="error" aria-live="assertive">
                      <span id={`helper-text-${name}`}>
                        {hasClientSideErrors && (
                          <Flex pb={1}>
                            <Warning size={16} />
                            <Text color="error" textStyle="caption" pl={1}>
                              {errors[name]?.message}
                            </Text>
                          </Flex>
                        )}
                        {hasInputMessages && (
                          <InputErrorMessage
                            messages={inputMessages}
                            isOnResetPassoword={
                              transaction?.nextStep?.name ===
                              'reset-authenticator'
                            }
                          />
                        )}
                      </span>
                    </Input.HelperText>
                  ) : null}
                </Box>
              )
            })}

          <Flex flexDirection="column" style={{ gap: '16px' }}>
            {stepName !== 'select-authenticator-enroll' && (
              <Button
                size="large"
                type="submit"
                variation={canSkip ? 'subtle' : 'fill'}
                disabled={buttonProcessing}
                onClick={handleSubmit(handleOktaSubmit)}
                width="100%"
              >
                {buttonProcessing ? (
                  <Flex justifyContent="space-around" data-testid="loader-dots">
                    <Box width="90px">
                      <Loader />
                    </Box>
                  </Flex>
                ) : (
                  Content[stepName]?.cta || 'Submit'
                )}
              </Button>
            )}
            {canSkip && (
              <Flex flexDirection="column" style={{ gap: '16px' }}>
                <SeperatorLine text={Content[stepName]?.divider || 'or'} />
                <Button size="large" width={1} onClick={handleSkip}>
                  Skip for Now
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      </form>

      {idps?.length ? (
        <Box>
          <SeperatorLine text={Content[stepName]?.divider || 'or'} />
          <Box mb={3} />
          <Layout gap="sm" rowGap="sm" variation="33-33-33">
            {idps.map(step => {
              const { idp } = step
              return (
                <Box key={idp?.id} py={2}>
                  <Button
                    variation="outline"
                    size="large"
                    width="100%"
                    data-testid={`idp-${idp?.name as string}`}
                    borderRadius="3xl"
                    color="border"
                    disabled={buttonsDisabled}
                    onClick={() => idpClick(idp?.name as string)}
                  >
                    <Image
                      src={`https://s1.pclncdn.com/design-assets/home/okta/${
                        idp?.name?.toLowerCase() as string
                      }.svg`}
                      width="24"
                      height="24"
                      alt={`Sign in with ${idp?.name as string}`}
                      m="auto"
                      loading="lazy"
                    />
                  </Button>
                </Box>
              )
            })}
          </Layout>
        </Box>
      ) : null}
      {transaction.nextStep.name === 'identify' && canResetPassword && (
        <Flex alignItems="center" justifyContent="center" my={4}>
          <Text fontWeight="bold">
            <Link href="/home/join?flow=recoverPassword">Reset Password</Link>
          </Text>
        </Flex>
      )}

      {stepName.includes('reset-authenticator') && (
        <Flex alignItems="center" justifyContent="center" my={4}>
          <Text fontWeight="bold">
            <Link href="/home/join?flow=unlockAccount">
              Unlock your account
            </Link>
          </Text>
        </Flex>
      )}
      {isPasswordVerified &&
        (isEmailVerified || isPhoneVerified || isOnPasswordVerifyPage) && (
          <SeperatorLine text={Content[stepName]?.divider || 'or'} />
        )}
      {stepName.includes('challenge-authenticator') && (
        <>
          {isOnPasswordVerifyPage && (
            <>
              {isEmailVerified && (
                <SwitchAuthMethod
                  text="Sign In with Email"
                  onClick={() => switchAuthMethod('email')}
                  buttonProcessing={buttonProcessing}
                />
              )}
              {isPhoneVerified && (
                <SwitchAuthMethod
                  text="Sign In with Phone"
                  onClick={() => switchAuthMethod('sms')}
                  buttonProcessing={buttonProcessing}
                />
              )}
              <ResetPassword
                onClick={() =>
                  userEmail
                    ? handleOktaSubmit({
                        forgetPasswordEmail: userEmail as string
                      })
                    : setErrorMessage('This account does not exist.')
                }
              />
            </>
          )}
          {isOnEmailVerifyCodePage && (
            <>
              {isPasswordVerified && (
                <SwitchAuthMethod
                  text="Sign In with Password"
                  onClick={() => switchAuthMethod('password')}
                  buttonProcessing={buttonProcessing}
                />
              )}
              {isPhoneVerified && (
                <SwitchAuthMethod
                  text="Sign In with Phone"
                  onClick={() => switchAuthMethod('sms')}
                  buttonProcessing={buttonProcessing}
                />
              )}
            </>
          )}
          {isOnPhoneVerifyCodePage && (
            <>
              {isPasswordVerified && (
                <SwitchAuthMethod
                  text="Sign In with Password"
                  onClick={() => switchAuthMethod('password')}
                  buttonProcessing={buttonProcessing}
                />
              )}

              {isEmailVerified && (
                <SwitchAuthMethod
                  text="Sign In with Email"
                  onClick={() => switchAuthMethod('email')}
                  buttonProcessing={buttonProcessing}
                />
              )}
            </>
          )}
        </>
      )}
    </BoxCustom>
  )
}

export default GeneralForm
