import React, { useCallback, useEffect } from 'react'
import { type IdxTransaction, type FlowIdentifier } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import analytics from '@/shared-utils/analytics'
import type { RootTypes } from './utils/types'
import GeneralForm from './GeneralForm'

function FlowPage({
  redirectUrl,
  transaction,
  setTransaction,
  errorMessage,
  setErrorMessage,
  setSlideInRight,
  slideInRight,
  flowParameter = 'register',
  showSpinner,
  setShowSpinner,
  buttonsDisabled,
  setButtonsDisabled
}: RootTypes) {
  const { oktaAuth } = useOktaAuth()

  const startFlow = useCallback(
    async (currentFlow: FlowIdentifier) => {
      setSlideInRight(currentFlow !== 'authenticate')
      setShowSpinner(true)
      let newTransaction
      try {
        // Start the transaction with the specified flow
        newTransaction = await oktaAuth.idx.startTransaction({
          flow: currentFlow
        })
      } catch (error) {
        const catchErrorMessage =
          error instanceof Error
            ? error.message
            : error?.toString() ?? 'Unknown error'

        analytics.logError({
          message: `Standalone - initial transaction failed with error: ${catchErrorMessage}`
        })
        setErrorMessage(
          `We're sorry, there was a problem loading our form. Please refresh the page to try again.`
        )
      }
      setTransaction(newTransaction as IdxTransaction)
      setShowSpinner(false)
    },
    [
      setSlideInRight,
      setShowSpinner,
      setTransaction,
      oktaAuth.idx,
      setErrorMessage
    ]
  )

  useEffect(() => {
    const checkFlow = async () => {
      let currentFlow = flowParameter
      if (oktaAuth) {
        const isSessionActive = await oktaAuth.session.exists()
        if (isSessionActive) {
          currentFlow = 'authenticate'
        }
      }
      if (currentFlow === 'register') {
        await oktaAuth.revokeRefreshToken()
        oktaAuth.tokenManager.clear()
        oktaAuth.transactionManager.clear()
        void oktaAuth.closeSession()
      }

      void startFlow(currentFlow)
      setErrorMessage('')
    }

    if (flowParameter !== undefined) {
      void checkFlow()
    }
  }, [flowParameter, startFlow, setErrorMessage, oktaAuth])
  return (
    <GeneralForm
      redirectUrl={redirectUrl}
      transaction={transaction}
      setTransaction={setTransaction}
      slideInRight={slideInRight}
      setSlideInRight={setSlideInRight}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      showSpinner={showSpinner}
      buttonsDisabled={buttonsDisabled}
      setButtonsDisabled={setButtonsDisabled}
    />
  )
}

export default FlowPage
